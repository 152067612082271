import React from "react";
import "../../statics/css/base.less";
import "./index.less";
import coLogo from "../../statics/image/home/co_img.png";

const Cooperation = () => {
  return (
    <div className="container cooperation">
      <div className="banner cooperation-banner"></div>
      <div className="block_bg_gray">
        <div className="block co_block_1 content-container">
          <div className="title">
            <div className="main-title">已服务客户</div>
          </div>
          <div className="content">
            <img src={coLogo} />
          </div>
        </div>
        <div className="block co_block_2 content-container">
          <div className="title">
            <div className="main-title">服务案例</div>
          </div>
          <div className="content">
            <div className="content-item block_2_img">
              <div className="modal content-container"></div>
              <div className="text">
                <div className="main-text">NIKE</div>
                <div className="sub-text">
                  丰湃为Nike在杭州、苏州、济南等15+城市门店提供店配服务。整合海量运力池搭配丰湃系统的智能排线，快速响应鞋服市场季节性及流行周期变换的配送特性，从多维度满足其市场波动需求，保障门店补货和出货的及时性
                </div>
              </div>
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        </div>
      </div>
      <div className="content-container block_bg_white">
        <div className="block co_block_3">
          <div className="content">
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <div className="main-text">新零售</div>
                <div className="sub-text">
                  丰湃响应新零售时代浪潮下商品类目日益丰富及高时效性要求，灵活运用中小型货车、厢式货车、冷藏车等多类型运输车辆，以多样化运力打破商品配送时间和空间的桎梏，动态助力新零售消费场景升级
                </div>
              </div>
            </div>
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <div className="main-text">家乐福</div>
                <div className="sub-text">
                  丰湃为家乐福在上海、南京、苏州等多城提供配送服务。专业的项目现场运营人员，依托自主研发的底盘系统——实现项目任务在线指派，项目全路由可视，实时监控运营质量，全流程规范司机操作，弥合高时效和高质量之间的隔阂，以严格的质量把控完美契合家乐福对品质的高标准、高要求
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cooperation;
