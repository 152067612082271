import { message } from 'antd';
import fetch from '@/utils/fetch';

class ChangeInfoModel {
  // eslint-disable-next-line class-methods-use-this
  async changeInfo(params) {
    const result = await fetch('/api/user/changeInfo', { type: 'POST', params });
    if (result) {
      message.success('修改成功');
      return true;
    }
    return false;
  }
}

export default ChangeInfoModel;
