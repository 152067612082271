import React, { useEffect, useState } from "react";
import { withStore } from "retalk";
import { Layout, Menu } from "antd";
import { NavLink, withRouter } from "react-router-dom";
import logo from "@/statics/image/logo.png";

import styles from "./Layout.scss";

const { Header, Content, Footer } = Layout;

const BasicLayout = ({ children, history }) => {
  const { pathname } = history.location;
  const [current, setCurrent] = React.useState([""]);
  useEffect(() => {
    const realPath =
      pathname === "/"
        ? "home"
        : pathname.includes("news")
        ? "news"
        : pathname.substring(1).toLowerCase();
    setCurrent([realPath]);
  }, [pathname]);

  /* useEffect(() => {
    addVisitor({ url: pathname });
    history.listen(location => {
      addVisitor({ url: location.pathname });
    });
  }, []); */

  // const curYear = new Date().getFullYear();

  return (
    <Layout>
      <Header theme="light">
        <NavLink to="/">
          <img alt="logo" src={logo} className={styles.logo} />
        </NavLink>
        <div>
          <Menu
            theme="light"
            selectedKeys={current}
            mode="horizontal"
            onClick={e => setCurrent(e.key)}
          >
            <Menu.Item key="home">
              <NavLink to="/" className="nav-text home">
                首页
              </NavLink>
            </Menu.Item>
            <Menu.Item key="product">
              <NavLink to="/product" className="nav-text product">
                产品与服务
              </NavLink>
            </Menu.Item>
            <Menu.Item key="cooperation">
              <NavLink to="/cooperation" className="nav-text cooperation">
                合作案例
              </NavLink>
            </Menu.Item>
            <Menu.Item key="aboutus">
              <NavLink to="/AboutUs" className="nav-text aboutus">
                关于我们
              </NavLink>
            </Menu.Item>
          </Menu>
        </div>
      </Header>
      <Content>{children}</Content>
      <Footer>
        {/* 021-64957167 */}
        <div className="footer-wrap center">
          <div className="qr-wrap">
            <div className="footer-wechat"></div>
            <div className="footer-sina"></div>
          </div>
          <div className="contact-wrap">
            {/* <h2>
              <i className="footer-phone"></i>021-60118525
            </h2> */}
            <p>
              工作日: 周一至周五&nbsp;&nbsp;&nbsp;&nbsp;时间:
              9:00-18:00&nbsp;&nbsp;&nbsp;&nbsp;WANGMENG@SFMAIL.SF-EXPRESS.COM
            </p>
            <p>
              <a href="https://beian.miit.gov.cn">
                © 2021 丰湃版权所有 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <i className="footer-police"></i>沪ICP备19022496号-1
              </a>
            </p>
          </div>
        </div>
      </Footer>
    </Layout>
  );
};

export default withRouter(withStore("basic")(BasicLayout));
