import { message } from 'antd';
import fetch from '@/utils/fetch';

class IssueModel {
  state = {
    shopInfo: {},
  };

  async getDetail(params) {
    const result = await fetch('/api/company/detail', { params });
    if (result) {
      this.setState({ shopInfo: result });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async submitIssue(params) {
    const result = await fetch('/api/company/submit', { type: 'POST', params });
    if (result) {
      message.success('提交成功，请等待工作人员审核', 5);
      return true;
    }
    return false;
  }

  clearShopInfo() {
    this.setState({ shopInfo: {} });
  }
}

export default IssueModel;
