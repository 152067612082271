import fetch from '@/utils/fetch';

class BasicModel {
  state = {};

  async addVisitor(params) {
    await fetch('/api/visitor/add', { type: 'POST', params });
  }
}

export default BasicModel;
