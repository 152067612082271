import { message } from 'antd';
import fetch from '@/utils/fetch';

class ModifyPasswdModel {
  state = {};

  // eslint-disable-next-line class-methods-use-this
  async changePassword(params) {
    const result = await fetch('/api/user/changePassword', { type: 'POST', params });
    if (result) {
      message.success('修改成功');
      return true;
    }
    return false;
  }
}

export default ModifyPasswdModel;
