exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../statics/image/cooperation/合作案例_02.jpg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../statics/image/cooperation/图片_05.jpg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../statics/image/cooperation/图片_11.jpg"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../statics/image/cooperation/jialefu.png"));

// Module
exports.push([module.id, ".cooperation .cooperation-banner {\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n.cooperation .block_bg_gray {\n  width: 100vw;\n  background-color: #f6f6f8;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n.cooperation .block .content {\n  display: grid;\n  grid-template-columns: 100%;\n}\n.cooperation .co_block_1 .content {\n  width: 1100px;\n  height: auto;\n}\n.cooperation .co_block_1 .content-item:hover .text .sub-text {\n  width: 90%;\n}\n.cooperation .co_block_2 {\n  position: relative;\n}\n.cooperation .co_block_2 .content {\n  min-height: 150px;\n}\n.cooperation .co_block_2 .content .content-item:nth-child(1) {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n  position: absolute;\n  height: 315px;\n}\n.cooperation .co_block_2 .content .content-item:nth-child(1) img {\n  height: 315px;\n  position: absolute;\n}\n.cooperation .co_block_3 {\n  margin-top: 175px;\n}\n.cooperation .co_block_3 .content .content-item {\n  height: 315px;\n}\n.cooperation .co_block_3 .content .content-item:nth-child(1) {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.cooperation .co_block_3 .content .content-item:nth-child(2) {\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n", ""]);

