import React from "react";
import { Provider } from "retalk";
import { HashRouter, Switch, Route } from "react-router-dom";

import store from "@/store";
import BasicLayout from "@/basic/Layout";
import Home from "@/pages/Home";
import Products from "@/pages/Products";
import Cooperation from "@/pages/Cooperation";
import AboutUs from "@/pages/AboutUs";

import "./App.scss";

const App = () => (
  <HashRouter>
    <Provider store={store}>
      <BasicLayout>
        <Switch>
          <Route path="/product">
            <Products />
          </Route>
          <Route path="/cooperation">
            <Cooperation />
          </Route>
          <Route path="/aboutus">
            <AboutUs />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
      </BasicLayout>
    </Provider>
  </HashRouter>
);

export default App;
