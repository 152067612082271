import React from "react";
import "../../statics/css/base.less";
import "./index.less";

const Products = () => {
  return (
    <div className="product container">
      <div className="product-baner banner"></div>
      <div className="content-container">
        {/* 城市配送 */}
        <div className="block pro_block_1">
          <div className="title">
            <div className="main-title">即时城配</div>
            <div className="sub-title">
              为本地生活类客户提供高时效、动态化城市配送服务
            </div>
          </div>
          <div className="content">
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="main-text">多样化运力</p>
                <p className="sub-text">自有运力与C端运力协同，降本增效</p>
              </div>
            </div>
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="main-text">全领域覆盖</p>
                <p className="sub-text">
                  客户覆盖连锁餐饮、商超、生鲜、快消、B2B平台、经销商、个体门店
                </p>
              </div>
            </div>
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="main-text">自研系统</p>
                <p className="sub-text">
                  自主研发的系统底盘全程监控，全程可视，过程透明化，运营数据化，迅速高效
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 新零售 */}
        <div className="block pro_block_2">
          <div className="title">
            <div className="main-title">新零售</div>
            <div className="sub-title">多元化的新零售城市配送服务</div>
          </div>
          <div className="content">
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="main-text">多样化运力</p>
                <p className="sub-text">
                  厢式货车、冷藏车、常规小面等多类型运输车辆，满足商品多样化的配送需求
                </p>
              </div>
            </div>
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="main-text">灵活的配送时效</p>
                <p className="sub-text">
                  可根据客户需求调整发配时间，全天候可配送，保障当日达
                </p>
              </div>
            </div>
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="main-text">可信赖的服务</p>
                <p className="sub-text">
                  标准化操作，损坏必赔，可全程温控，新鲜直送
                </p>
              </div>
            </div>
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="main-text">成本集约</p>
                <p className="sub-text">
                  C端运力，直送串点，同时提供返筐服务，进一步提升单车运输效率
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* 仓间调拨 */}
        <div className="block pro_block_3">
          <div className="title">
            <div className="main-title">仓间调拨</div>
            <div className="sub-title">高效便捷，支持末端定制化的配送服务</div>
          </div>
          <div className="content">
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="sub-text">
                  承接客户工厂/总仓（CDC）、区域配送中心（RDC）、配送中心（DC）之间的货物调拨
                </p>
              </div>
            </div>
            <div className="content-item">
              <div className="modal"></div>
              <div className="text">
                <p className="sub-text">
                  支持按重量、体积、整车等灵活报价，为客户降本增效
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
