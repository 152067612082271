import React from "react";
import "../../statics/css/base.less";
import "./index.less";

const AboutUs = () => {
  return (
    <div className="aboutus container">
      <div className="banner aboutus-banner"></div>
      <div className="content-container">
        <div className="block us_block_1">
          <div className="title">业务简介</div>
          <div className="detail">
            <p>
              上海丰湃供应链管理有限公司，成立于2019年1月，总部位于上海，是顺丰同城旗下全资子公司，提供即时城配运营服务。
            </p>
            <p>
              即时城配运营商，为餐饮、商超、生鲜、快消等本地生活类客户提供高时效、动态化城市配送服务。
            </p>
          </div>
        </div>
        <div className="block us_block_2">
          <div className="featrue_item">
            <div className="icon">
              <img
                src={require("../../statics/image/aboutus/落地运营团队.png")}
                alt=""
              />
            </div>
            <div className="text">业务覆盖60+城</div>
          </div>
          <div className="featrue_item">
            <div className="icon">
              <img
                src={require("../../statics/image/aboutus/员工数量.png")}
                alt=""
              />
            </div>
            <div className="text">员工数量500+</div>
          </div>
          <div className="featrue_item">
            <div className="icon">
              <img
                src={require("../../statics/image/aboutus/研发中心.png")}
                alt=""
              />
            </div>
            <div className="text">北京成都2大研发中心</div>
          </div>
          <div className="featrue_item">
            <div className="icon">
              <img
                src={require("../../statics/image/aboutus/日营运车辆.png")}
                alt=""
              />
            </div>
            <div className="text">日营运车辆5000+</div>
          </div>
        </div>
        <div className="block us_block_3">
          <ul>
            <li>2019年：丰湃成立</li>
            <li>2020年：业务覆盖50城，丰湃系统1.0上线</li>
            <li>2021年：业务覆盖60+城</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
