import { setStore } from "retalk";
import BasicModel from "@/basic/model";
import HomeModel from "@/pages/Home/model";
import ChangeInfoModel from "@/pages/AccountInfo/model";
import ModifyPasswdModel from "@/pages/ModifyPassword/model";
import IssueModel from "@/pages/Issue/model";

const store = setStore({
  basic: BasicModel,
  home: HomeModel,
  changeInfo: ChangeInfoModel,
  changePassword: ModifyPasswdModel,
  issueMessage: IssueModel
});

export default store;
