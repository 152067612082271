exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../statics/image/product/_-产品与服务_02.jpg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../statics/image/product/城市配送_09.jpg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../statics/image/product/城市配送_11.jpg"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../statics/image/product/城市配送_13.jpg"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../../statics/image/product/社区团购_07.jpg"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("../../statics/image/product/社区团购_09.jpg"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("../../statics/image/product/社区团购_17.png"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("../../statics/image/product/社区团购_19.jpg"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("../../statics/image/product/仓间调拨_07.jpg"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("../../statics/image/product/仓间调拨_09.png"));

// Module
exports.push([module.id, ".product .product-baner {\n  width: 100%;\n  height: 400px;\n  min-width: 1000px;\n  background: url(" + ___CSS_LOADER_URL___0___ + ") no-repeat;\n  background-size: cover;\n  background-position: center 0;\n  -webkit-background-size: cover;\n  -o-background-size: cover;\n}\n.product .pro_block_1 .content {\n  height: 206px;\n}\n.product .pro_block_1 .content .content-item:nth-child(1) {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n.product .pro_block_1 .content .content-item:nth-child(2) {\n  background-image: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n.product .pro_block_1 .content .content-item:nth-child(3) {\n  background-image: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n.product .pro_block_2 .content {\n  height: 476px;\n  grid-template-columns: 50% 50%;\n}\n.product .pro_block_2 .content .content-item:nth-child(1) {\n  background-image: url(" + ___CSS_LOADER_URL___4___ + ");\n}\n.product .pro_block_2 .content .content-item:nth-child(2) {\n  background-image: url(" + ___CSS_LOADER_URL___5___ + ");\n}\n.product .pro_block_2 .content .content-item:nth-child(3) {\n  background-image: url(" + ___CSS_LOADER_URL___6___ + ");\n}\n.product .pro_block_2 .content .content-item:nth-child(4) {\n  background-image: url(" + ___CSS_LOADER_URL___7___ + ");\n}\n.product .pro_block_3 .content {\n  height: 250px;\n  grid-template-columns: 50% 50%;\n}\n.product .pro_block_3 .content .content-item:nth-child(1) {\n  background-image: url(" + ___CSS_LOADER_URL___8___ + ");\n}\n.product .pro_block_3 .content .content-item:nth-child(2) {\n  background-image: url(" + ___CSS_LOADER_URL___9___ + ");\n}\n", ""]);

